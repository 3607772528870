import { Box, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  exportTransactions,
  getTransactionsCollection,
  TOrderColumn,
  TransactionsFilter,
  TTransactionsCollection,
} from 'api/endpoints/transactions';
import AppLink from 'components/appLink/appLink';
import { AppStatusHighlight } from 'components/appStatusHighlight';
import { AppTableProps } from 'components/appTable';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Transaction } from 'types/commonTypes';
import { formatDateToDay } from 'utils/funcs/formatDate';
import useFilters from 'utils/hooks/filters/useFilters';
import * as React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAppSelector } from 'stores/store';
import { TRANSACTIONS_URL } from './appRoutes/appRoutesConst';
import { TransactionsTable } from 'components/appTable/TransactionsTable';
import useAuthentication from 'utils/hooks/useAuthentication';
import { FileType } from 'utils/funcs/downloadFile';
import { PageTitle } from 'components/pageTitle';
import { errorHandling } from 'utils/funcs/errorHandling';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import FullscreenLoader from 'components/fullscreenLoader';
import useQueryParameters from 'utils/hooks/useQueryParameters';
import { createHandleSortModelChange } from 'utils/hooks/createHandleSortModelChange';

interface TSortModel {
  field: TOrderColumn;
  sort: string;
}

export const TransactionsPage: React.FC = () => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation('common', { keyPrefix: 'pages.transactionsPage' });
  const { networkId } = useAppSelector((state) => state.user);
  const [data, setData] = useState<TTransactionsCollection>();
  const { currentRole } = useAuthentication();
  const getQueryParameter = useQueryParameters();
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const pageParam = useMemo(() => getQueryParameter('page'), [location.search]);
  const pageSizeParam = useMemo(() => getQueryParameter('pageSize'), [location.search]);
  const queryOptionsQP = useMemo(
    () => getQueryParameter('queryOptions'),
    [location.search],
  );
  const filtersQP = useMemo(() => getQueryParameter('filters'), [location.search]);

  const [page, setPage] = useState<number>(pageParam ? Number(pageParam) : 0);
  const [pageSize, setPageSize] = useState<number>(
    pageSizeParam ? Number(pageSizeParam) : 20,
  );
  const isFirstRender = useRef(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const updatedParams = new URLSearchParams(location.search);
    if (Number(updatedParams.get('page')) !== page)
      updatedParams.set('page', String(page));
    if (Number(updatedParams.get('pageSize')) !== pageSize)
      updatedParams.set('pageSize', String(pageSize));

    navigate({ search: updatedParams.toString() }, { replace: true });
  }, [page, pageSize, navigate, location.search]);

  const [queryOptions, setQueryOptions] = React.useState<TSortModel | null>(
    queryOptionsQP ?? {
      field: 'date_terminal',
      sort: 'DESC',
    },
  );
  const onSearchHandler = async () => {
    setIsLoading(true);
    if (networkId.length > 0) {
      await getTransactionsCollection(networkId, params)
        .then((res) => {
          setData({ ...res });
        })
        .catch((e) => errorHandling(e))
        .finally(() => setIsLoading(false));
    }
  };

  const handleSortModelChange = useCallback(
    createHandleSortModelChange({
      params: urlParams,
      navigate,
      setQueryOptions,
      setPage,
    }),
    [navigate, urlParams, setQueryOptions, setPage],
  );

  const { filters, filterValues, reset, loading } = useFilters({
    filters: [
      'APPROVAL_CODE',
      'PAN',
      'AMOUNT_RANGE',
      'DATE_RANGE',
      'PAYMENT_TERMINAL_NAME',
      'MERCHANT',
      'LOCATION',
    ],
    advancedFilters: [
      'VARIABLE_SYMBOL',
      'TRANSACTION_STATUS',
      'TRANSACTION_TYPE',
      'UUID',
      'TIME_SOURCE',
      'CARD_ISSUER',
      'CARD_SCHEMA',
      'PAYMENT_DEVICE',
      'PAYMENT_TYPE',
      'CARD_ORIGIN',
      'CARD_PRODUCT',
      'TIP',
      'CASHBACK',
    ],
    namePairs: [
      { filterName: 'APPROVAL_CODE', queryname: 'approval_code' },
      { filterName: 'MERCHANT', queryname: 'merchant' },
      { filterName: 'LOCATION', queryname: 'location' },
      { filterName: 'PAN', queryname: 'pan' },
      { filterName: 'AMOUNT_RANGE', queryname: ['amount_from', 'amount_to'] },
      { filterName: 'DATE_RANGE', queryname: ['date_from', 'date_to'] },
      { filterName: 'PAYMENT_TERMINAL_NAME', queryname: 'terminal' },
      { filterName: 'TRANSACTION_STATUS', queryname: 'status' },
      { filterName: 'TRANSACTION_TYPE', queryname: 'transaction_type' },
      { filterName: 'UUID', queryname: 'uuid' },
      { filterName: 'VARIABLE_SYMBOL', queryname: 'vs' },
      { filterName: 'TIME_SOURCE', queryname: 'use_date_terminal' },
      { filterName: 'CARD_ISSUER', queryname: 'card_issuer' },
      { filterName: 'CARD_SCHEMA', queryname: 'card_schema' },
      { filterName: 'PAYMENT_DEVICE', queryname: 'payment_device' },
      { filterName: 'PAYMENT_TYPE', queryname: 'payment_type' },
      { filterName: 'CARD_ORIGIN', queryname: 'card_origin' },
      { filterName: 'CARD_PRODUCT', queryname: 'card_product' },
      { filterName: 'TIP', queryname: 'is_tip' },
      { filterName: 'CASHBACK', queryname: 'is_cashback' },
    ],
    options: { showSaveFilter: true },
    isSearchButton: true,
    onSearchHandler,
  });

  useEffect(() => {
    reset(filtersQP);
  }, []);

  const dateFrom = dayjs(filtersQP?.['DATE_RANGE']?.[0] ?? filterValues.date_from)
    .startOf('day')
    .format('YYYY-MM-DDTHH:mm:ss');

  const dateTo = dayjs(filtersQP?.['DATE_RANGE']?.[1] ?? filterValues.date_to)
    .endOf('day')
    .format('YYYY-MM-DDTHH:mm:ss');

  const params: TransactionsFilter = {
    ...filterValues,
    approval_code: filtersQP?.['APPROVAL_CODE'] ?? filterValues.approval_code ?? '',
    card_issuer: filtersQP?.['CARD_ISSUER'] ?? filterValues.card_issuer ?? '',
    card_origin: filtersQP?.['CARD_ORIGIN'] ?? filterValues.card_origin ?? '',
    card_product: filtersQP?.['CARD_PRODUCT'] ?? filterValues.card_product ?? '',
    card_schema: filtersQP?.['CARD_SCHEMA'] ?? filterValues.card_schema ?? '',
    location: filtersQP?.['LOCATION'] ?? filterValues.location ?? '',
    merchant: filtersQP?.['MERCHANT'] ?? filterValues.merchant ?? '',
    pan: filtersQP?.['PAN'] ?? filterValues.pan ?? '',
    payment_device: filtersQP?.['PAYMENT_DEVICE'] ?? filterValues.payment_device ?? '',
    payment_type: filtersQP?.['PAYMENT_TYPE'] ?? filterValues.payment_type ?? '',
    status: filtersQP?.['TRANSACTION_STATUS'] ?? filterValues.status ?? '',
    terminal: filtersQP?.['PAYMENT_TERMINAL_NAME'] ?? filterValues.terminal ?? '',
    is_tip: filtersQP?.['TIP'] ?? filterValues.is_tip ?? '',
    is_cashback: filtersQP?.['CASHBACK'] ?? filterValues.is_cashback ?? '',
    transaction_type:
      filtersQP?.['TRANSACTION_TYPE'] ?? filterValues.transaction_type ?? '',
    uuid: filtersQP?.['UUID'] ?? filterValues.uuid ?? '',
    vs: filtersQP?.['VARIABLE_SYMBOL'] ?? filterValues.vs ?? '',
    amount_from:
      filtersQP?.['AMOUNT_RANGE']?.[0] !== undefined
        ? filtersQP['AMOUNT_RANGE'][0] * 100
        : filterValues.amount_from !== undefined
        ? filterValues.amount_from * 100
        : undefined,
    amount_to:
      filtersQP?.['AMOUNT_RANGE']?.[1] !== undefined
        ? filtersQP['AMOUNT_RANGE'][1] * 100
        : filterValues.amount_to !== undefined
        ? filterValues.amount_to * 100
        : undefined,
    limit: pageSize,
    offset: page * pageSize,
    date_from: dateFrom,
    date_to: dateTo,
    use_date_terminal: filtersQP?.['TIME_SOURCE'] ?? filterValues?.use_date_terminal,
    order_column: queryOptions?.field,
    order_type: queryOptions?.sort.toUpperCase(),
  };

  useEffect(() => {
    onSearchHandler();
  }, [queryOptions, page, pageSize, networkId, currentRole]);

  const handleTransactionsExport = async (type: FileType) =>
    await exportTransactions(networkId, type, {
      ...params,
    });

  const TRANSACTION_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'date_terminal',
        headerName: t('table.date'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction>) => {
          const { row } = params;
          return (
            <div>
              <Typography component="div" fontWeight={'bold'}>
                {formatDateToDay(row.date_terminal, 'DD.MM.YYYY')}
              </Typography>
              <Typography component="div">
                {formatDateToDay(row.date_terminal, 'HH:mm:ss')}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'amount',
        headerName: t('table.amountAndCurrency'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction, Transaction>) => {
          const { row } = params;
          return (
            <Box>
              <Typography sx={{ marginRight: 0.5 }} component="span" fontWeight={'bold'}>
                {(row?.amount / 100).toFixed(2)}
              </Typography>
              <Typography component="span">{row.currency}</Typography>
            </Box>
          );
        },
      },
      {
        field: 'status',
        headerName: t('table.status'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction, Transaction>) => {
          const { row } = params;
          return (
            <AppStatusHighlight status={row.status}>{row.status}</AppStatusHighlight>
          );
        },
      },
      { field: 'type', headerName: t('table.opType'), flex: 1 },
      {
        field: 'approval_code',
        headerName: t('table.approvalCode'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction, Transaction>) => {
          const { row } = params;

          return <>{row.approval_code}</>;
        },
      },
      {
        field: 'terminal_identifier',
        headerName: t('table.terminalId'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction, Transaction>) => {
          const { row } = params;

          return <>{row.terminal_identifier}</>;
        },
      },
      {
        field: 'uuid',
        headerName: t('table.uuid'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction, Transaction>) => {
          const { row } = params;

          return (
            <AppLink
              to={`${TRANSACTIONS_URL}/${row.uuid}/${row.merchant_identifier}/${row.location_identifier}/${row.terminal_identifier}`}
            >
              {row.uuid}
            </AppLink>
          );
        },
      },
      { field: 'pan', headerName: t('table.pan'), flex: 1 },
      {
        field: 'goToDetail',
        sortable: false,
        headerName: '',
        renderCell: (params: GridRenderCellParams<Transaction>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink
                  to={`${TRANSACTIONS_URL}/${row.uuid}/${row.merchant_identifier}/${row.location_identifier}/${row.terminal_identifier}`}
                  isGreyLink
                >
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [resolvedLanguage],
  );

  if (loading) return <FullscreenLoader />;

  return (
    <>
      <PageTitle title={t('pageTitle')} />
      {filters}
      <TransactionsTable
        isLoading={isLoading}
        page={page}
        isNextPage={data?.paging.nextPage ?? false}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        rows={data?.data ?? []}
        columns={TRANSACTION_COLUMNS}
        appTitle={t('tableTitle')}
        showSettings={false}
        handleSortModelChange={handleSortModelChange}
        rowCount={data?.paging.count ?? 0}
        exportMenuItems={[
          {
            onClick: () => handleTransactionsExport('xlsx'),
            label: 'XLSX',
          },
          {
            onClick: () => handleTransactionsExport('csv'),
            label: 'CSV',
          },
        ]}
      />
    </>
  );
};
