import { Box, styled, Typography, useTheme } from '@mui/material';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import switchioLogo from 'assets/images/switchio_blue_small.svg';
import { TransactionDetail } from 'types/commonTypes';
import { useTranslation } from 'react-i18next';

const MAX_CARD_WIDTH = 400;

const CardWithRef = styled('div')(({ theme: { spacing } }) => ({
  maxWidth: `${MAX_CARD_WIDTH}px`,
  backgroundColor: 'white',
  padding: spacing(2),
}));

type ValueLabelObj = {
  value: string;
  label: string;
};

interface ReceiptHeaderProps {
  merchantName: string;
  address: string;
  city: string;
  cin: string;
}

const ReceiptHeader: React.FC<ReceiptHeaderProps> = ({
  merchantName,
  address,
  city,
  cin,
}) => {
  return (
    <Box sx={{ py: 3 }}>
      <Typography variant="h5" component="div">
        {merchantName}
      </Typography>
      <Box display={'flex'}>
        <Typography variant="body2" mr={1} component="div">
          {address}
        </Typography>
      </Box>
      <Typography variant="body2" component="div">
        {city}
      </Typography>
      <Typography variant="body2" component="div">
        {cin}
      </Typography>
    </Box>
  );
};

interface ReceiptContainerProps {
  data: TransactionDetail;
}

export const ReceiptContainer: React.FC<ReceiptContainerProps> = ({ data }) => {
  const { palette } = useTheme();
  const parentRef = useRef<HTMLDivElement>(null);
  const [parentWidth, setParrentWidth] = useState(800);
  const { t } = useTranslation('common', { keyPrefix: 'components.receiptContainer' });

  const updateWidth = useCallback(() => {
    if (parentRef && parentRef.current) {
      const { width } = parentRef.current.getBoundingClientRect();
      setParrentWidth(width);
    }
  }, [parentRef]);

  useLayoutEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const values: ValueLabelObj[][] = [
    [{ value: data.date_terminal, label: t('dateAndTime') }],
    [{ value: `${(data?.amount / 100).toFixed(2)} ${data.currency}`, label: t('total') }],
    [
      { value: data.terminal.name, label: t('terminal') },
      { value: data.pan, label: t('pan') },
      { value: data.card_issuer, label: t('cardIssuer') },
      { value: data.type, label: t('type') },
      { value: data.approval_code, label: t('approvalCode') },
      { value: data.response_code, label: t('responseCode') },
      { value: data.status, label: t('status') },
    ],
  ];
  return (
    <>
      <CardWithRef ref={parentRef}>
        <ReceiptHeader
          merchantName={data.merchant_name}
          address={data.terminal.location.street}
          city={`${data.terminal.location.zip_code} ${data.terminal.location.city}`}
          cin={data.cin}
        />
        <Box
          sx={{
            borderTop: `2px solid ${palette.border.secondary}`,
            borderBottom: `2px solid ${palette.border.secondary}`,
          }}
        >
          {values.map((value, index) => {
            return (
              <Box
                sx={{
                  py: 3,
                  ':not(:last-of-type)': {
                    borderBottom: `2px dashed ${palette.border.secondary}`,
                  },
                }}
                key={index}
              >
                {value.map((valueKeyPair, index) => (
                  <Box
                    sx={{
                      px: 2,
                      py: 0.5,
                      display: 'grid',
                      gap: 1,
                      gridTemplateColumns: 'repeat(2, 1fr)',
                    }}
                    key={index}
                  >
                    <Typography variant={'subtitle2'}>{valueKeyPair.label}</Typography>
                    <Typography fontWeight={'bold'} variant={'h6'}>
                      {valueKeyPair.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{ mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <Typography fontWeight={'bold'} variant={'h6'}>
            {t('thankYou')}
          </Typography>
          <Typography variant={'subtitle2'}>{t('merchReceipt')}</Typography>
          <Box sx={{ my: 2 }}>
            <img width={82} height={14} src={switchioLogo} />
          </Box>
        </Box>
      </CardWithRef>
    </>
  );
};
